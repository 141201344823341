<template>
  <atoms-c-input
    id="emailCode"
    v-model="emailCode"
    class="input_form code"
    maxlength="6"
    :placeholder="codePlaceholder"
    :error="emailCodeValidStateMap.err"
    :disabled="isMatchedCode"
    required
  >
    <span class="txt_time">{{ codeTimerStr }}</span>

    <!-- <atoms-outlined-button
      v-if="props.hideBtn === false"
      :text="`인증 ${isMatchedCode ? '완료' : '확인'}`"
      :disabled="disabled"
      @click="() => verifyCode('email')"
    ></atoms-outlined-button> -->
  </atoms-c-input>
</template>

<script lang="ts" setup>
import {
  ERR_MSG_OBJ,
  EMAIL_MSG_OBJ,
  EMAIL_READY,
  EMAIL_SENDED,
  EMAIL_RE_READY
} from '@/utils/constants'
import { throttle } from 'lodash-es'

const props = defineProps({
  email: {
    type: String,
    default: ''
  },
  userId: {
    type: String,
    default: ''
  },
  sendStatus: {
    type: Number,
    default: EMAIL_READY
  },
  hideBtn: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String,
    default: '메일로 발송된 인증번호를 입력해주세요.'
  }
})
const nuxtApp = useNuxtApp()
const { $api, $device } = nuxtApp
const isMobile = ($device as any).isMobileOrTablet

const { handleCommonError } = useCommonError()
const { startExpireInterval, codeTimerId, stopExipreInterval, codeTimerStr } =
  useEmailExpireInterval()
const sendStatusTimerId = ref<ReturnType<typeof setTimeout>>()

const emailCode = ref('')
const emailVerifyToken = ref('')
const emailCodeValidStateMap = reactive({
  err: false,
  valid: false,
  msg: ''
})

watch(emailCode, () => {
  setEmailCodeValidStateMap({
    err: false,
    valid: false,
    msg: ''
  })
})
watch(
  () => props.sendStatus,
  (newVal) => {
    console.log('inputEmailCode sendStatus changed', newVal)
    if (newVal === EMAIL_SENDED) {
      startExpireInterval()

      // interval이 1초 뒤에 시작하기에 1초 후에 발송완료로 변경
      sendStatusTimerId.value = setTimeout(
        () => emit('setSendStatus', EMAIL_RE_READY),
        1000
      )
    } else if (newVal === EMAIL_READY) {
      // 초기화
      stopExipreInterval()
      clearTimeout(sendStatusTimerId.value)
      emailCode.value = ''
      emailVerifyToken.value = ''
    }
  }
)
watch(
  () => codeTimerId.value,
  (newVal) => {
    if (typeof newVal === 'number') {
      if (newVal > 0) {
        // 타이머가 갱신되면 에러 메시지 초기화
        setEmailCodeValidStateMap({
          err: false,
          valid: false,
          msg: ''
        })
      } else if (newVal === 0 && props.sendStatus !== EMAIL_READY) {
        setEmailCodeValidStateMap({
          err: true,
          valid: false,
          msg: EMAIL_MSG_OBJ.EMAIL_CODE_EXPIRED
        })
      }
    }
  }
)

const disabled = computed(() => {
  return (
    (props.sendStatus === EMAIL_RE_READY && codeTimerId.value === 0) ||
    /*  emailCode.value.length === 0 || */
    isMatchedCode.value
  )
})
const codePlaceholder = computed(() => {
  if (isMobile) return '인증번호를 입력해주세요.'
  return props.placeholder
})
const isMatchedCode = computed(() => {
  return emailCodeValidStateMap.valid
})

const emit = defineEmits(['setSendStatus', 'setEmailCodeValidStateMap'])

const setEmailCodeValidStateMap = ({
  err,
  valid,
  msg
}: {
  err?: boolean
  valid?: boolean
  msg?: string
}) => {
  if (typeof err !== 'undefined') emailCodeValidStateMap.err = err
  if (typeof valid !== 'undefined') emailCodeValidStateMap.valid = valid
  if (typeof msg !== 'undefined') emailCodeValidStateMap.msg = msg
}

const verifyCode = async (type: 'email' | 'password' | 'unlock') => {
  console.log('verifyCode', type)
  try {
    let apiName
    const params: {
      keyCode: string | number
      email?: string
      userId?: string
    } = { keyCode: emailCode.value }
    switch (type) {
      case 'email':
        apiName = 'verifyCodeForUpdateEmail'
        params.email = props.email

        break
      case 'password':
        apiName = 'verifyCodeForPassword'
        params.email = props.email
        break
      case 'unlock':
        apiName = 'verifyCodeForUnlockAccount'
        params.userId = props.userId
        break
    }
    if (typeof $api.account[apiName] === 'undefined') {
      return
    }
    const resp = await $api.account[apiName](params)
    const { code, data } = resp
    switch (code) {
      case 0:
        stopExipreInterval()
        setEmailCodeValidStateMap({
          err: false,
          valid: true,
          msg: EMAIL_MSG_OBJ.EMAIL_VALID
        })

        if (data && type === 'password') {
          const { token } = data
          if (token.length > 0) {
            emailVerifyToken.value = token
          }
        }
        break
      case 99001:
      case 90054:
        setEmailCodeValidStateMap({
          err: true,
          valid: false,
          msg: EMAIL_MSG_OBJ.EMAIL_NOT_CODE
        })
        break
      default:
        handleCommonError({ code })
        break
    }
  } catch (e) {
    console.log(e)
    handleCommonError(e)
  }
}

defineExpose({
  disabled,
  emailVerifyToken,
  codeTimerId,
  emailCodeValidStateMap,
  isMatchedCode,
  verifyCode
})
</script>
<style scoped lang="scss">
.input_form.code {
  align-items: center;
  gap: 8px;
  &.error {
    .txt_time {
      color: $sys-red0;
    }
  }
}
.txt_time {
  font-size: 12px;
  font-weight: 700;
  line-height: 150%; /* 18px */
  letter-spacing: -0.48px;
  color: $blue1;
}
</style>
