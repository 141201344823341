const MIN = 60
export const useEmailExpireInterval = (min = 5, sec = 1) => {
  const codeTimerStr = ref<string>('') // 00:00
  const duration = min * MIN
  const dur = ref(duration)
  const callback = () => {
    --dur.value
    if (dur.value < 1) {
      stop()
    }
  }

  const { start, stop, timerId } = useInterval(callback)

  const startExpireInterval = () => {
    stopExipreInterval()
    start(sec * 1000)
  }
  const stopExipreInterval = () => {
    dur.value = duration
    stop()
  }

  const displayTime = (time: number) => {
    const minutes = Math.floor(time / MIN)
    const seconds = time % MIN
    codeTimerStr.value = `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
  }
  watch(
    () => dur.value,
    (val) => {
      displayTime(val)
    }
  )

  return {
    codeTimerStr,
    codeTimerId: timerId,
    startExpireInterval,
    stopExipreInterval
  }
}
