<template>
  <atoms-c-input
    id="email"
    v-model="email"
    class="input_form email"
    :error="hasError"
    maxlength="50"
    :placeholder="props.placeHolder"
    clear
    @keyup="onKeyUp"
  >
    <atoms-outlined-button
      v-if="props.type === 'edit'"
      :text="sendCodeBtnTxt"
      :disabled="isDisabledSendCodeBtn"
      @click="sendCodeForUpdateEmail"
    />
  </atoms-c-input>
</template>
<script lang="ts" setup>
import { debounce } from 'lodash-es'
import { EMAIL_VALIDATOR, EMAIL_MSG_OBJ, ERR_MSG_OBJ } from '@/utils/constants'

const nuxtApp = useNuxtApp()
const { $api } = nuxtApp
const { handleCommonError } = useCommonError()

const props = defineProps({
  placeHolder: {
    type: String,
    default: '이메일을 입력해주세요'
  },
  isMatchedCode: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'edit' // edit, findId, findPwd
  },
  isHideBtn: {
    type: Boolean,
    default: false
  }
})
const email = ref('')
const emailValidStateMap = reactive({
  err: false,
  valid: false,
  msg: ''
})

const sendStatus = ref<number>(0) // 0: 발송전 , 1: 발송 완료, 2: 재전송가능
const sendCodeBtnTxt = computed(() => {
  switch (sendStatus.value) {
    case 0:
      return '인증 요청'
    case 1:
      return '발송 완료'
    case 2:
      return '다시 받기'
  }
})

const hasError = computed(() => {
  return sendStatus.value === 0 && emailValidStateMap.err
})

const isDisabledSendCodeBtn = computed(() => {
  return (
    emailValidStateMap.valid === false || // 규칙 통과 실패
    email.value.length === 0 || // 빈 이메일
    sendStatus.value === 1 || // 발송 완료 (1초)
    props.isMatchedCode // 인증 완료
  )
})

watch(email, () => {
  setEmailValidStateMap({
    err: false,
    valid: false,
    msg: ''
  })
})

const setEmailValidStateMap = ({
  err,
  valid,
  msg
}: {
  err?: boolean
  valid?: boolean
  msg?: string
}) => {
  if (typeof err !== 'undefined') emailValidStateMap.err = err
  if (typeof valid !== 'undefined') emailValidStateMap.valid = valid
  if (typeof msg !== 'undefined') emailValidStateMap.msg = msg
}
const onKeyUp = debounce(function (e: KeyboardEvent) {
  const input = e.target as HTMLInputElement
  if (input) {
    const { value } = input
    if (value.length === 0) return
    if (!EMAIL_VALIDATOR.email(value)) {
      setEmailValidStateMap({
        err: true,
        valid: false,
        msg: EMAIL_MSG_OBJ.EMAIL_RULE
      })

      return
    }
    setEmailValidStateMap({
      err: false,
      valid: true,
      msg: ''
    })
  }
}, 500)

const setSendStatus = (value: number) => {
  sendStatus.value = value
}
const sendCodeForUpdateEmail = async () => {
  try {
    const resp = await $api.account.sendCodeForUpdateEmail(email.value)
    const { code } = resp
    switch (code) {
      case 90011: // 이미 사용 중인 이메일입니다.
      case 90014: // 올바른 이메일 형식이 아닙니다.
        setEmailValidStateMap({
          err: true,
          valid: false,
          msg: ERR_MSG_OBJ[code]
        })
        //emailValidStateMap.msg = EMAIL_MSG_OBJ.EMAIL_RULE

        break
      case 0:
        emailValidStateMap.err = false
        setEmailValidStateMap({
          err: false,
          valid: true,
          msg: ''
        })
        setSendStatus(1)
        break
      default:
        handleCommonError(resp)
        break
    }
  } catch (e) {
    handleCommonError(e)
    setSendStatus(0)
  }
}

const findUserId = async (): Promise<number | undefined> => {
  try {
    const resp = await $api.account.findUserId(email.value)
    const { code } = resp
    if (code !== 0) {
      setEmailValidStateMap({
        err: true,
        valid: true,
        msg: ERR_MSG_OBJ[code]
      })
    }
    return code
  } catch (e) {
    handleCommonError(e)
  }
}
const findPassword = async () => {
  try {
    setEmailValidStateMap({
      err: false,
      valid: false,
      msg: ''
    })
    const resp = await $api.account.findPassword(email.value)
    const { code } = resp
    if (code === 0) {
      setSendStatus(1)
    } else {
      setEmailValidStateMap({
        err: true,
        valid: true,
        msg: ERR_MSG_OBJ[code]
      })
    }
  } catch (e) {
    handleCommonError(e)
  }
}
const sendCodeForUnlockAccount = async (userId: string) => {
  try {
    const resp = await $api.account.sendCodeForUnlockAccount({
      email: email.value,
      userId
    })
    const { code } = resp
    if (code === 0) {
      setSendStatus(1)
    } else {
      setEmailValidStateMap({
        err: true,
        valid: true,
        msg: ERR_MSG_OBJ[code]
      })
    }
  } catch (e) {
    handleCommonError(e)
  }
}
/* const unlockAccount  = aync ()=> {


} */

// TODO : throttle
//const foobar = throttle(sendCodeForEmail, 1000, { trailing: false })
defineExpose({
  emailValue: email,
  sendStatus,
  emailValidStateMap,
  setSendStatus,
  sendCodeForUpdateEmail,
  sendCodeForUnlockAccount,
  findUserId,
  findPassword
})
</script>

<style lang="scss" scoped>
.input_form.email {
  align-items: center;
  gap: 8px;
  > ::v-deep(input) {
    line-height: 150%;
  }
}
</style>
