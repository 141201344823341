import { showErrorToast } from '@/assets/js/toast'
import { ERR_MSG_OBJ } from '@/utils/constants'
export const useCommonError = () => {
  const handleCommonError = (e: unknown) => {
    const _e = e as any
    if (_e.name === 'FetchError' && _e.message?.includes('aborted')) {
      return
    }
    const code = _e?.code
    const msg = ERR_MSG_OBJ[code] ?? '알 수 없는 오류가 발생했습니다.'

    showErrorToast(msg)
  }
  return { handleCommonError }
}
